import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration)
const removeUnwantedSeparators = (string) => {
  string = string.replaceAll('\n', ',')
  string = string.replaceAll(';', ',')
  return string
}

const getTimeDifferenceInDays = (reportedDate, processDurationInMs) => {
  let start = dayjs(reportedDate)
  let end = start.add(processDurationInMs, 'ms')
  return end.diff(start, 'day')
}

const getTimeDifferenceInDaysBetweenTwoDates = (aDate, bDate) => {
  let start = dayjs(aDate)
  let end = dayjs(bDate)
  return end.diff(start, 'day')
}

const getTimeDifferenceInDaysUntilNow = (reportedDate) => {
  return dayjs().diff(dayjs(reportedDate), 'day')
}

export {
  removeUnwantedSeparators,
  getTimeDifferenceInDays,
  getTimeDifferenceInDaysBetweenTwoDates,
  getTimeDifferenceInDaysUntilNow,
}
